.custom-tab-nav {
   .tab-content {
      padding: 0 0 0 2rem;
   }
}

.second-color {
   color: $second-color;
}

.danger {
   color: $red;
}

.optional-field {
   color: $primary-lighter;
}

.custom-disabled:disabled,
.custom-file-input[disabled]~.custom-file-label,
.custom-file-input:disabled~.custom-file-label,
.form-control:disabled~.addon-for-number,
.form-control[disabled]~.addon-for-number {
   background-color: hsl(0, 0%, 95%);
   border-color: hsl(0, 0%, 90%);
   color: hsl(0, 0%, 60%);
}

.custom-file-label::after {
   display: none;
}

.form-label {
   font-weight: 500;
}

.form-check-input {
   border-color: $second-color;

   &:checked {
      accent-color: $second-color;
      background-color: $second-color;
      border-color: $second-color;
   }

   &:hover {
      accent-color: $second-color;
      border-color: $second-color;
   }
}

input[type="checkbox"] .form-check-input {
   border-color: $second-color;

   &:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e") !important;
   }
}

.hr-line {
   margin: 3rem 20rem;
   border: 1px solid $third-color;
}

.mt-2rem {
   margin-top: 2rem;
}

.mb-2rem {
   margin-bottom: 2rem;
}

.ms-2rem {
   margin-left: 2rem;
}

.me-2rem {
   margin-right: 2rem;
}

.mx-2rem {
   margin-left: 2rem;
   margin-right: 2rem;
}

.my-2rem {
   margin-top: 2rem;
   margin-bottom: 2rem;
}

.p-2rem {
   padding: 2rem;
}

.ps-2rem {
   padding-left: 2rem;
}

.pe-2rem {
   padding-right: 2rem;
}

.px-2rem {
   padding-left: 2rem;
   padding-right: 2rem;
}

.py-2rem {
   padding-top: 2rem;
   padding-bottom: 2rem;
}

.row-thanks {
   margin-top: 1rem;
   display: flex;
   justify-content: center;
   align-items: center;
   text-align: center;
   text-align: -webkit-center;
   flex-direction: column-reverse;

   @media (min-width: 992px) {
      margin-top: 4rem;
      flex-direction: row;
      width: 60%;
      margin-right: auto;
      margin-left: auto;
   }

   @media (min-width: 1600px) {
      width: 45%;
   }

   @media (min-width: 2560px) {
      margin-top: 7rem;
      width: 35%;
   }

   @media (min-width: 4096px) {
      margin-top: 15rem;
      width: 25%;
   }

   .text-thanks {
      width: fit-content;
      //text-align: center;

      @media (min-width: 2236px) {
         //margin: 0 5rem 0 auto;
      }
   }

   .img-thanks {
      max-height: 16rem;
      margin-bottom: 3rem;
   }
}

.show-pass {
   position: absolute;
   right: 0;
   height: 100%;
}

.multiselect-no-flex {
   .css-g1d714-ValueContainer {
      flex-direction: unset;
      //align-items: flex-start !important;
      //-webkit-box-align: start !important;
      //-webkit-align-items: flex-start !important;
   }
}

.multiselect-noflex__value-container {
   flex-direction: column;
   align-items: baseline !important;
}

.save-button {
   display: block;
   margin-left: auto;
   margin-right: auto;
   margin-top: 2.5rem;
   width: 11rem;
   font-size: 1.2rem;
}

#statusbar {
   .simple-box {
      color: #ffffff;
      font-weight: bold;
      padding: 20px 15px !important;
   }

   .success {
      background-color: #c2c923;
   }

   .error {
      background-color: #cb1b4a;
   }

   .elaborating {
      background-color: #09A8B0;
   }
}

.panel-menu {
   .side-nav {
      .menu-link {
         padding-right: 0 !important;
         justify-content: center;
      }

      .menu-item {
         padding-top: 0.5rem;
         padding-bottom: 0.5rem;
      }

      svg {
         width: 25px;
         height: 25px;
      }

      .arrow-ico {
         svg {
            width: 12px !important;
            transform: rotate(0);
            transition: transform 0.2s ease-in-out;
         }
      }

      .rotateArrow {
         svg {
            transform: rotate(180deg);
            transition: transform 0.2s ease-in-out;
         }
      }

      .dropdown-content {
         overflow: hidden;
         opacity: 1;
         height: 0;
         transition: all 0.5s ease-in-out;
      }

      .dropdown-content-open {
         opacity: 1;
         //height: 65px;
         transition: all 0.5s ease-in-out;
      }
   }

   &.expanded {
      .side-nav {
         .menu-link {
            padding-right: 2rem !important;
            padding-left: 2rem !important;
            justify-content: flex-start;
         }
      }
   }
}

#icons-page {
   svg {
      width: 35px;
      height: 35px;
      margin: 1rem;
      //fill: $primary-lighter;

      .box,
      .esagono {
         fill: $second-color !important;
      }

      .symbol,
      .ico {
         fill: black !important;
      }
   }
}

.p-inputnumber-input {
   width: inherit;
}

.ag-pinned-right-header {
   border-left: 0 !important;
}

.ag-header-cell.text-center {
   .ag-header-cell-label {
      justify-content: center;
   }
}

.ag-header-cell.text-end {
   .ag-header-cell-label {
      justify-content: end;
   }
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
   color: $second-color !important;
}

.dropdown-menu {
   &.to-center {
      left: 50%;
      right: auto;
      transform: translate(-50%, 0);
   }

   &.to-left {
      /*    right: 50%;
      left: auto;
      transform: translate(-50%, 0); */

      transform: translate(-100.5%, -90%);
   }

   &.to-left-report {
      /*    right: 50%;
      left: auto;
      transform: translate(-50%, 0); */

      transform: translate(-101%, -3rem);
      padding: 0.7rem 1rem !important;
   }

   &.to-top {
      /*    right: 50%;
         left: auto;
         transform: translate(-50%, 0); */

      transform: translate(-70%, -118%);
   }

   &.filter-table {
      border-color: $second-color;
   }
}

.ico-disabled {
   fill: $second-color-disabled;
   color: $second-color-disabled;
   cursor: default;
}

.ico-enabled {
   fill: $second-color;
   color: $second-color;
   cursor: pointer;
}

.progress.aqua-bar {
   .progress-bar {
      background-color: $second-color;
   }
}

.header-center-with-filter {
   .ag-cell-label-container {
      span.ag-header-icon {
         position: absolute;
         right: 10px;
      }

      span.ag-filter-icon {
         position: relative;
         right: 5px;
      }

      div.ag-header-cell-label {

         .ag-sort-ascending-icon,
         .ag-sort-descending-icon {
            position: absolute;
            left: -70%;
         }
      }
   }
}

.ag-header-green.ag-header-cell {
   background-color: $green;
}

.ag-header-yellow.ag-header-cell {
   background-color: $yellow;
}

.ag-header-red.ag-header-cell {
   background-color: $red;
}

.filter-text-box {
   min-height: 2.243rem;
}

.form-floating>label {
   z-index: inherit;
}

.report-quantity-section .col .row {
   padding: 1rem 0rem 1rem 0.25rem;

   @media (min-width: 2236px) {
      padding: 1rem 0.5rem;
   }
}

#multiline-chart {
   .line {
      stroke-width: 2;
      fill: none;

      &:hover {
         fill: none;
      }
   }

   .axis path {
      stroke: black;
   }
}


.chevron-filter {
   padding: 3px;
   display: inline-block;
   margin-left: 3px;

   path {
      stroke: $primary-light-saturate !important;
      stroke-width: 1.3 !important;
   }
}

.reset-filter {
   //padding: 1px;

   path {
      fill: white !important;
      //stroke-width: 1 !important;
   }
}

svg.disabled {
   path {
      fill: $lightgrey;
   }
}

.nav-pills {
   width: fit-content;
   background-color: $dropdowngrey;
   border-radius: 100px;
   font-weight: $font-w-regular;
   font-size: $font-20px;
   line-height: 21px;
   padding-top: 5px;
   padding-bottom: 5px;
   gap: 5px;

   &.nav-fill {
      width: 100%;
   }

   &:first-child {
      padding-left: 5px;
   }
   &:last-child {
      padding-right: 5px;
   }

   .nav-item .nav-link {
      padding: 10px 25px ;
      border-radius: 100px;
      color: $primary-light;

      &:hover, &.active {
         background-color: $title-color;
         color: white !important;
         //font-weight: $font-w-semibold;
      }

      &.disabled {
         color: $disabled-tab !important;
      }
   }
}

.tooltip {
   z-index: 9999 !important;
}