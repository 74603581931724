.modal-75w {
   --bs-modal-width: 75%;
}

.modal-90w {
   --bs-modal-width: 90%;
}

.taxonomies-fields:first-child {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   grid-column-gap: 10px;
   grid-row-gap: 10px;

   .col {
      margin-bottom: 0.5rem;
   }
}

.textarea-readonly-list {
   background-color: transparent !important;
   border-color: transparent !important;
   color: unset !important;
   padding-left: 0 !important;
   resize: none !important;
}

.detail-upload {
   .form-text {
      font-size: 1.1rem;
   }
}

.ico-green {
   fill: $green !important;
}
.ico-yellow {
   fill: $yellow !important;
}
.ico-red {
   fill: $red !important;
}